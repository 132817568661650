import {
  cloneDeep, findIndex, filter, slice,
  concat, map, find, isNil, each,
  trim,
} from 'lodash';
import { startOfDay, endOfDay } from 'date-fns';
import * as api from '@/services/api';
import socket from '@/services/socket';
import types from './mutation-types';
import { formatBetsData, formatBetData } from '@/components/bet-ticker/betTicker-helper';
import computeStartsAt from '@/utils/computeStartsAt';
import { updateLiability } from '@/services/helpers/liability-updater';
import { parseAllFilters, composeAllFilters } from '@/services/helpers/bet-ticker-filters';

export default {
  loadAllBets({ commit, getters }, loadOlder) {
    const allBets = cloneDeep(getters.betTickerList);
    const offset = loadOlder ? filter(allBets, (bet) => !bet.isBetPart).length : 0;
    const filterOperator = getters.selectedSearchField === 'eventName' ? 'includesInsensitive' : 'equalTo';
    const options = {
      first: 50,
      offset,
      filter: {
        acceptanceTime: {
          isNull: false,
        },
      },
    };
    if (getters.betTickerListSearch) {
      options.filter = {
        ...options.filter,
        betPartsByBetId: {
          every: {
            huddleContentInfoByBetPartId: {
              eventByEventId: {
                [getters.selectedSearchField]: { [filterOperator]: getters.betTickerListSearch },
              },
            },
          },
        },
      };
    }
    if (getters.onlyFlaggedBets) {
      options.filter = {
        ...options.filter,
        isFlagged: {
          equalTo: true,
        },
      };
    }
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    api.useAllBetsQuery(options)
      .then((res) => {
        let bets = res.data.allBets.nodes ?? [];
        bets = formatBetsData(bets);
        if (loadOlder) bets = concat(allBets, bets);

        commit(types.UPDATE_BET_TICKER_LIST, bets);
      })
      .catch(() => {
        commit(types.UPDATE_BET_TICKER_LIST, []);
      })
      .finally(() => {
        commit(types.SET_BET_TICKER_LIST_LOADING, false);
      });
  },
  loadBetTickerBets({ commit, getters }, { betTickerId, loadOlder }) {
    const allBets = cloneDeep(getters.betTickerList);
    const offset = loadOlder ? filter(allBets, (bet) => !bet.isBetPart).length : 0;
    const options = {
      bettickerid: betTickerId,
      first: 50,
      offset,
    };
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    if (getters.onlyFlaggedBets) {
      options.filter = {
        ...options.filter,
        isFlagged: {
          equalTo: true,
        },
      };
    }
    api.useAllDynamicBetsQuery(options)
      .then((res) => {
        let bets = res.data.allBetsDynamic.nodes ?? [];
        bets = formatBetsData(bets);
        if (loadOlder) bets = concat(allBets, bets);

        commit(types.UPDATE_BET_TICKER_LIST, bets);
      })
      .catch(() => {
        commit(types.UPDATE_BET_TICKER_LIST, []);
      })
      .finally(() => {
        commit(types.SET_BET_TICKER_LIST_LOADING, false);
      });
  },
  toggleParlayBet({ getters, commit }, betId) {
    let betList = cloneDeep(getters.betTickerList);
    const singleBetIndex = findIndex(betList, (bet) => bet.betId === betId);
    const singleBet = betList[singleBetIndex];
    singleBet.toggled = !singleBet.toggled;
    if (singleBet.toggled) {
      betList = [
        ...slice(betList, 0, singleBetIndex + 1),
        ...singleBet.betParts,
        ...slice(betList, singleBetIndex + 1),
      ];
    } else {
      betList = filter(betList, (bet, index) => (index < singleBetIndex + 1 || index > singleBetIndex + singleBet.betParts.length));
    }
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  setActiveSidebarOption({ commit }, sidebarOption) {
    commit(types.SET_ACTIVE_SIDEBAR_OPTION, sidebarOption);
  },
  setSelectedSearchField({ commit }, field) {
    commit(types.SET_SELECTED_SEARCH_FIELD, field);
  },
  updateBetTickerSearchValue({ commit }, value) {
    commit(types.UPDATE_BET_TICKER_SEARCH, value);
  },
  resetBets({ commit }) {
    commit(types.SET_BET_TICKER_LIST_LOADING, true);
    commit(types.UPDATE_BET_TICKER_LIST, []);
  },
  async loadBetTickerConfigurations({ commit, dispatch }) {
    const allBetTickersResponse = await api.useAllBetTickersQuery();
    const allBetTickers = allBetTickersResponse?.data?.allBetTickerConfigurations?.nodes;
    commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
    const marketDisplayConfigurationsResponse = await api.loadAllSportsMarketDisplayConfigurations();
    const marketDisplayConfigurations = marketDisplayConfigurationsResponse?.data?.allMarketDisplayConfigurations?.nodes || [];
    const marketsDisplayConfigurationObj = marketDisplayConfigurations.reduce((markets, market) => ({
      ...markets,
      [`${market.sportId}_${market.marketCode}_${market.selections}`]: market,
    }), {});
    commit(types.SET_ALL_SPORTS_DISPLAY_MARKETS_CONFIGURATION, marketsDisplayConfigurationObj);
    if (!filter(allBetTickers, ({ enabled }) => enabled)?.length) {
      dispatch('loadAllBets');
      socket.subscribeToBetTicker();
      socket.subscribeToBetInfo();
    } else {
      dispatch('selectBetTicker', filter(allBetTickers, ({ enabled }) => enabled)[0]);
    }
  },
  unloadBetTicker({ commit, getters, dispatch }) {
    if (getters.betTickerList?.length) commit(types.UPDATE_BET_TICKER_LIST, []);
    const { selectedBetTicker } = getters;
    const betTickerId = selectedBetTicker?.betTickerId;
    socket.unsubscribeFromBetTicker(betTickerId);
    socket.unsubscribeFromBetInfo();
    dispatch('selectBetTicker', null);
  },
  async reloadBetTicker({ getters, dispatch }) {
    dispatch('unloadBetTicker');
    if (!getters.selectedBetTicker?.betTickerId) {
      dispatch('loadBetTickerConfigurations');
      return;
    }
    const id = getters.selectedBetTicker.betTickerId;
    await dispatch('loadBetTickerConfigurations');
    dispatch('selectBetTicker', find(getters.betTickerConfigurations, { betTickerId: id }));
  },
  updateBetTickerBets({ commit, getters }, bet) {
    const formattedBet = formatBetData(bet);
    const allBets = cloneDeep(getters.betTickerList);
    allBets.unshift(formattedBet);
    commit(types.UPDATE_BET_TICKER_LIST, allBets);
  },
  async selectBetTicker({
    commit, dispatch, getters, rootGetters,
  }, betTicker) {
    const { selectedBetTicker } = getters;
    const betTickerId = selectedBetTicker?.betTickerId;
    socket.unsubscribeFromBetTicker(betTickerId);
    socket.unsubscribeFromBetInfo();
    if (!betTicker) {
      commit(types.SELECT_BET_TICKER, null);
      dispatch('loadAllBets');
      socket.subscribeToBetTicker();
      socket.subscribeToBetInfo();
      return;
    }

    const selectedOddFormat = rootGetters.selectedOddFormat?.name || 'american';
    const parsedActiveFilters = await parseAllFilters(betTicker.filters, { selectedOddFormat });
    const parsedInactiveFilters = await parseAllFilters(map(betTicker.inactiveFilters, (type) => ({ type })), { selectedOddFormat });

    const mappedBetTicker = {
      ...betTicker,
      inactiveFilters: parsedInactiveFilters,
      filters: parsedActiveFilters,
    };
    commit(types.SELECT_BET_TICKER, mappedBetTicker);
    if (!mappedBetTicker.enabled) {
      commit(types.UPDATE_BET_TICKER_LIST, []);
      return;
    }
    dispatch('loadBetTickerBets', { betTickerId: mappedBetTicker.betTickerId });
    socket.subscribeToBetTicker(mappedBetTicker.betTickerId);
    socket.subscribeToBetInfo();
  },
  async createBetTicker({ commit, getters, dispatch }) {
    const createdBetTicker = await api.createBetTicker();
    const betTicker = {
      betTickerId: createdBetTicker.data,
      betTickerName: 'New Ticker',
      filters: [],
      inactiveFilters: [],
      enabled: true,
    };
    const betTickers = cloneDeep(getters.betTickerConfigurations);
    commit(types.SET_BET_TICKER_CONFIGURATIONS, concat(betTickers, [betTicker]));
    dispatch('selectBetTicker', betTicker);
  },
  betTickerAction({ commit, getters, dispatch }, { betTicker, action, name }) {
    if (action === 'rename') {
      api.renameBetTicker({ betTickerId: betTicker.betTickerId, name })
        .then(() => {
          const betTickers = cloneDeep(getters.betTickerConfigurations);
          const renamedBetTicker = find(betTickers, (ticker) => ticker.betTickerId === betTicker.betTickerId);
          if (!renamedBetTicker) return;
          renamedBetTicker.betTickerName = name;
          commit(types.SET_BET_TICKER_CONFIGURATIONS, betTickers);
        })
        .catch((err) => console.log(err));
    }
    if (action === 'duplicate') {
      api.duplicateBetTicker(betTicker.betTickerId)
        .then(() => {
          api.useAllBetTickersQuery()
            .then((response) => {
              const allBetTickers = response?.data?.allBetTickerConfigurations?.nodes;
              commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
    if (action === 'delete') {
      api.deleteBetTicker(betTicker.betTickerId)
        .then(() => {
          const betTickers = cloneDeep(getters.betTickerConfigurations);
          const filteredBetTickers = filter(betTickers, (ticker) => ticker.betTickerId !== betTicker.betTickerId);
          commit(types.SET_BET_TICKER_CONFIGURATIONS, filteredBetTickers);
        })
        .catch((err) => console.log(err));
    }
    if (action === 'toggle-ticker') {
      api.updateBetTicker({ ...betTicker, enabled: !betTicker.enabled }).then(() => {
        const { selectedBetTicker } = getters;
        const betTickers = cloneDeep(getters.betTickerConfigurations);
        const updatedBetTickers = map(betTickers, (ticker) => {
          if (ticker.betTickerId === betTicker.betTickerId) {
            return { ...ticker, enabled: !betTicker.enabled };
          }
          return ticker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedBetTickers);

        // If the selected ticker is the one being toggled, update the selected ticker
        if (selectedBetTicker?.betTickerId === betTicker.betTickerId) {
          const { showDisabledTickers, betTickerConfigurations } = getters;
          // If show disabled tickers is enabled, we can keep the selected ticker
          if (showDisabledTickers) {
            const selectedBetTickerConfiguration = find(betTickerConfigurations, (ticker) => ticker.betTickerId === selectedBetTicker?.betTickerId);
            dispatch('selectBetTicker', { ...selectedBetTickerConfiguration, enabled: !betTicker.enabled });
          } else {
          // If show disabled tickers is disabled, select the first enabled ticker if available
            dispatch('selectBetTicker', find(betTickerConfigurations, { enabled: true }) || null);
          }
        }
      });
    }
  },
  addBetTickerHighlight({ getters, commit, dispatch }, payload) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const currentHighlights = betTicker.highlights || [];
    const newHighlight = { ...payload.highlight, index: currentHighlights.length };
    const updatedHighlights = [...currentHighlights, newHighlight];
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  replaceBetTickerHighlight({ getters, commit, dispatch }, payload) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const updatedHighlights = map(betTicker.highlights || [], (highlight, index) => {
      if (payload.index !== index) return highlight;
      return payload.highlight;
    });
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  removeBetTickerHighlight({ getters, commit, dispatch }, highlightIndex) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    const updatedHighlights = cloneDeep(betTicker.highlights);
    updatedHighlights.splice(highlightIndex, 1);
    let updatedTicker = { ...betTicker, highlights: updatedHighlights };
    const oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, updatedHighlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  setBetTickerHighlights({ getters, commit, dispatch }, highlights) {
    const { betTickerId = '' } = getters.selectedBetTicker;
    const betTicker = find(getters.betTickerConfigurations, { betTickerId });
    if (!betTicker) return;

    let updatedTicker = { ...betTicker, highlights };
    let oldTickers = cloneDeep(getters.betTickerConfigurations);
    let updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
      if (betTickerId !== ticker.betTickerId) return ticker;
      return updatedTicker;
    });
    commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
    dispatch('selectBetTicker', updatedTicker);

    api.updateBetTickerHighlights(betTickerId, highlights)
      .then((response) => {
        updatedTicker = { ...betTicker, highlights: response.data };
        oldTickers = cloneDeep(getters.betTickerConfigurations);
        updatedTickers = map(getters.betTickerConfigurations, (ticker) => {
          if (betTickerId !== ticker.betTickerId) return ticker;
          return updatedTicker;
        });
        commit(types.SET_BET_TICKER_CONFIGURATIONS, updatedTickers);
        dispatch('selectBetTicker', updatedTicker);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_BET_TICKER_CONFIGURATIONS, oldTickers);
        dispatch('selectBetTicker', betTicker);
      });
  },
  toggleFilters({ getters, commit }, filterItem) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    if (find(selectedBetTicker.inactiveFilters, { id: filterItem.id })) {
      selectedBetTicker.inactiveFilters = filter(selectedBetTicker.inactiveFilters, (filterObj) => filterObj.id !== filterItem.id);
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    if (find(selectedBetTicker.filters, { id: filterItem.id })) {
      selectedBetTicker.filters = filter(selectedBetTicker.filters, (filterObj) => filterObj.id !== filterItem.id);
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    selectedBetTicker.inactiveFilters.push(filterItem);
    commit(types.SELECT_BET_TICKER, selectedBetTicker);
  },
  updateFilters({ getters, commit }, filterItem) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    if (find(selectedBetTicker.inactiveFilters, { id: filterItem.id })) {
      selectedBetTicker.inactiveFilters = map(selectedBetTicker.inactiveFilters, (inactiveFilter) => {
        if (inactiveFilter.id === filterItem.id) {
          return filterItem;
        }
        return inactiveFilter;
      });

      if (filterItem.conditionType && !isNil(filterItem.parameter)) {
        selectedBetTicker.inactiveFilters = filter(selectedBetTicker.inactiveFilters, (filterObj) => filterObj.id !== filterItem.id);
        selectedBetTicker.filters.push(filterItem);
        commit(types.SELECT_BET_TICKER, selectedBetTicker);
        return;
      }
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    if (find(selectedBetTicker.filters, { id: filterItem.id })) {
      selectedBetTicker.filters = map(selectedBetTicker.filters, (activeFilter) => {
        if (activeFilter.id === filterItem.id) {
          return filterItem;
        }
        return activeFilter;
      });

      if (!filterItem.conditionType || isNil(filterItem.parameter) || filterItem.parameter === '') {
        selectedBetTicker.filters = filter(selectedBetTicker.filters, (filterObj) => filterObj.id !== filterItem.id);
        selectedBetTicker.inactiveFilters.push(filterItem);
        commit(types.SELECT_BET_TICKER, selectedBetTicker);
        return;
      }
      commit(types.SELECT_BET_TICKER, selectedBetTicker);
      return;
    }
    selectedBetTicker.filters = map(selectedBetTicker.filters, (activeFilter) => {
      if (activeFilter.id === filterItem.id) {
        return filterItem;
      }
      return activeFilter;
    });
    commit(types.SELECT_BET_TICKER, selectedBetTicker);
  },
  saveFilters({ getters, commit, dispatch }, selectedOddFormat) {
    const selectedBetTicker = cloneDeep(getters.selectedBetTicker);
    const composedActiveFilters = composeAllFilters(selectedBetTicker.filters, { selectedOddFormat });
    const composedInactiveFilters = composeAllFilters(selectedBetTicker.inactiveFilters, { selectedOddFormat });
    const betTicker = {
      betTickerId: selectedBetTicker.betTickerId,
      enabled: selectedBetTicker.enabled,
      inactiveFilters: map(composedInactiveFilters, (filterItem) => filterItem.type),
      filters: composedActiveFilters,
    };
    api.updateBetTicker(betTicker)
      .then(() => {
        api.useAllBetTickersQuery()
          .then((response) => {
            const allBetTickers = response?.data?.allBetTickerConfigurations?.nodes;
            commit(types.SET_BET_TICKER_CONFIGURATIONS, allBetTickers);
            const findSelectedBetTicker = find(allBetTickers, (ticker) => ticker.betTickerId === betTicker.betTickerId);
            dispatch('selectBetTicker', findSelectedBetTicker);
            dispatch('setActiveSidebarOption', false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  },
  closeFilters({ getters, dispatch }, betTickerId) {
    const betTickers = getters.betTickerConfigurations;
    const findSelectedBetTicker = find(betTickers, (ticker) => ticker.betTickerId === betTickerId);
    dispatch('selectBetTicker', findSelectedBetTicker);
    dispatch('setActiveSidebarOption', false);
  },
  toggleShowDisabledTickers({ getters, commit, dispatch }) {
    const showDisabledTickers = !getters.showDisabledTickers;
    const { selectedBetTicker } = getters;
    // In case the selected ticker is disabled, we need to select the first enabled ticker if available
    if (selectedBetTicker && !selectedBetTicker.enabled) {
      const { betTickerConfigurations } = getters;
      dispatch('selectBetTicker', find(betTickerConfigurations, { enabled: true }) || null);
    }
    commit(types.SHOW_DISABLED_TICKERS, showDisabledTickers);
  },
  toggleFlag(_, { betId, isFlagged }) {
    const action = isFlagged ? api.unflag : api.flag;
    action(betId)
      .catch((error) => {
        console.error(error);
      });
  },
  setIsBetFlagged({ getters, commit }, { betId, isFlagged }) {
    const betList = cloneDeep(getters.betTickerList);
    const singleBet = find(betList, (bet) => bet.betId === betId);
    singleBet.isFlagged = isFlagged;
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  refreshHighlightedBets({ getters, commit }) {
    const betList = cloneDeep(getters.betTickerList);
    each(betList, (bet) => {
      const betObj = bet;
      if (bet.highlightedRow) betObj.highlightedRow = false;
    });
    commit(types.UPDATE_BET_TICKER_LIST, betList);
  },
  setOnlyFlaggedBets({ getters, commit, dispatch }, newOnlyFlaggedBets) {
    commit(types.SET_ONLY_FLAGGED_BETS, newOnlyFlaggedBets);

    if (getters.selectedBetTicker) {
      dispatch('loadBetTickerBets', {
        betTickerId: getters.selectedBetTicker.betTickerId,
        loadOlder: false,
      });
    } else {
      dispatch('loadAllBets', false);
    }
  },
  async loadLiabilityEvents({ getters, commit }, {
    sport, competition, from, to, state, search, page, limit,
  }) {
    const marketCodes = ['RESULT'];
    const lineMarketCodes = ['GOAL_OVER_UNDER', 'POINT_OVER_UNDER', 'RUN_OVER_UNDER', 'GOAL_HANDICAP', 'POINT_HANDICAP', 'RUN_HANDICAP'];
    const startDate = from ? startOfDay(new Date(from)) : '';
    const endDate = to ? endOfDay(new Date(to)) : '';
    if (getters.liabilityEvents?.length) {
      socket.unsubscribeFromEventListLiabilities(map(getters.liabilityEvents, (e) => e.eventId));
    }

    try {
      commit(types.SET_LIABILITY_EVENTS_LOADING, true);

      const queryOptions = {
        first: limit,
        offset: (page - 1) * limit,
        filter: {
          startsAt: {
            greaterThanOrEqualTo: startDate || computeStartsAt(),
          },
        },
        condition: {},
        marketsFilter: {},
        lineMarketsFilter: {},
      };

      if (sport) {
        queryOptions.filter.sportId = {
          in: [sport],
        };
        queryOptions.lineMarketsFilter.marketCode = {
          in: lineMarketCodes,
        };
        queryOptions.marketsFilter.marketCode = {
          in: marketCodes,
        };
      }

      if (endDate) {
        queryOptions.filter.startsAt.lessThanOrEqualTo = endDate;
      }

      if (search) {
        queryOptions.filter = {
          ...queryOptions.filter,
          eventName: {
            includesInsensitive: search,
          },
        };
      }

      if (competition?.length) {
        queryOptions.filter.competitionId = {
          in: competition,
        };
      }

      if (state?.length) {
        queryOptions.filter.matchState = {
          in: state,
        };
      }

      const response = await api.useAllLiabilityEventsQuery(queryOptions);
      commit(types.UPDATE_LIABILITY_EVENTS, response.data?.allEvents);
      const eventIds = map(response.data?.allEvents?.events, (event) => event.eventId);
      socket.subscribeToEventListLiabilities(eventIds);
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_LIABILITY_EVENTS, []);
    } finally {
      commit(types.SET_LIABILITY_EVENTS_LOADING, false);
    }
  },
  async loadLiabilityFiltersMeta({ commit, dispatch }, data) {
    const startDate = data.from ? startOfDay(new Date(data.from)) : '';
    if (data.competitionsOnly) {
      const competitions = await api.getCompetitionsWithEvents({
        sportId: data.sport,
        startDate: startDate || computeStartsAt(),
      });
      commit(types.UPDATE_LIABILITY_FILTERS_META, { competitions });
      dispatch('loadLiabilityEvents', data);
      return;
    }
    const response = await api.fetchEligibleSports();
    const selectedSportId = data.sport || response.data.sports.nodes[0]?.sportId;
    const competitions = await api.getCompetitionsWithEvents({
      sportId: selectedSportId,
      startDate: startDate || computeStartsAt(),
    });
    commit(types.UPDATE_LIABILITY_FILTERS_META, { sports: response.data.sports.nodes, competitions });
    dispatch('loadLiabilityEvents', data);
  },
  updateEventListLiabilities({ getters, commit }, data) {
    if (data.messageType === 'SELECTION_LIABILITY') return;
    const liabilityEvents = cloneDeep(getters.liabilityEvents);
    const eventToUpdate = find(liabilityEvents, { eventId: data.eventId });
    if (!eventToUpdate) return;
    const mergedEvent = updateLiability(eventToUpdate, data);
    if (mergedEvent.update) {
      const eventIndex = findIndex(liabilityEvents, { eventId: eventToUpdate.eventId });
      liabilityEvents[eventIndex] = mergedEvent.event;
      commit(types.UPDATE_LIABILITY_EVENTS, { events: liabilityEvents, totalCount: getters.liabilityEventsRowCount });
    }
  },
  updateLiabilityEventInfo({ getters, commit }, data) {
    if (data.messageType === 'event-primary-feeds-updated') return;
    const { eventId, matchStatus: matchState } = data ?? {};

    if (!getters.liabilityEvents?.length) return;

    const events = cloneDeep(getters.liabilityEvents);
    const foundEvent = find(events, { eventId });
    const foundEventIndex = find(events, { eventId });
    if (!foundEvent) return;
    foundEvent.matchState = matchState;
    events[foundEventIndex] = foundEvent;
    commit(types.UPDATE_LIABILITY_EVENTS, { events, totalCount: getters.liabilityEventsRowCount });

    const liabilityEvent = cloneDeep(getters.liabilityEvent);
    if (!liabilityEvent || eventId !== liabilityEvent?.eventId) return;
    liabilityEvent.matchState = matchState;
    commit(types.UPDATE_LIABILITY_EVENT, liabilityEvent);
  },
  reloadLiabilities({ getters }) {
    if (getters.liabilityEvents?.length) {
      socket.subscribeToEventListLiabilities(map(getters.liabilityEvents, (e) => e.eventId));
    }
  },
  clearLiabilityEvents({ getters, commit }) {
    if (getters.liabilityEvents?.length) {
      socket.unsubscribeFromEventListLiabilities(map(getters.liabilityEvents, (e) => e.eventId));
    }
    commit(types.UPDATE_LIABILITY_EVENTS, { events: [], totalCount: 0 });
    commit(types.UPDATE_LIABILITY_FILTERS_META, { sports: [], competitions: [] });
  },
  async loadLiabilityEvent({ commit }, eventId) {
    try {
      commit(types.SET_LIABILITY_EVENT_LOADING, true);

      const response = await api.useLiabilityEventQuery({ id: eventId });
      const event = response.data?.liabilityEvent;
      commit(types.UPDATE_LIABILITY_EVENT, event);
      const { marketDisplayConfigurations } = await api.getMarketDisplayConfigurations({ sportId: event?.sportId });
      const liabilityMarkets = event.marketLiabilities.nodes;
      const liabilityMarketsObject = liabilityMarkets.reduce((markets, market) => ({ ...markets, [market.marketId]: market }), {});
      const liabilityMarketSelections = event.selectionLiabilities.nodes;
      const liabilityMarketSelectionsObject = liabilityMarketSelections.reduce((markets, selection) => {
        let marketSelections = markets[selection.marketId];
        if (marketSelections) {
          marketSelections.push(selection);
        } else {
          marketSelections = [selection];
        }
        return {
          ...markets,
          [selection.marketId]: marketSelections,
        };
      }, {});
      const liabilityEventMarketsDisplayConfiguration = marketDisplayConfigurations.reduce((markets, market) => ({
        ...markets,
        [`${market.marketCode}_${market.selections}`]: market,
      }), {});
      commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, liabilityEventMarketsDisplayConfiguration);
      commit(types.SET_LIABILITY_EVENT_MARKETS, liabilityMarketsObject);
      commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, liabilityMarketSelectionsObject);
      try {
        const queryOptions = {
          filter: {
            sportId: {
              in: event.sportId,
            },
          },
        };
        const marketGroupsResponse = await api.getAllMarketGroups(queryOptions);
        commit(types.SET_LIABILITY_MARKET_GROUPS, marketGroupsResponse?.marketGroups?.nodes || []);
      } catch {
        commit(types.SET_LIABILITY_MARKET_GROUPS, []);
      }
      socket.subscribeToEventLiabilities(eventId);
    } catch (error) {
      console.error('Querying events failed', error);
      commit(types.UPDATE_LIABILITY_EVENT, null);
      commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, {});
      commit(types.SET_LIABILITY_EVENT_MARKETS, {});
      commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, {});
    } finally {
      commit(types.SET_LIABILITY_EVENT_LOADING, false);
    }
  },
  clearLiabilityEvent({ commit }) {
    commit(types.UPDATE_LIABILITY_EVENT, null);
    commit(types.SET_LIABILITY_DISPLAY_MARKETS_CONFIGURATION, {});
    commit(types.SET_LIABILITY_EVENT_MARKETS, {});
    commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, {});
  },
  updateEventLiabilities({ state, getters, commit }, data) {
    const liabilityEvent = cloneDeep(getters.liabilityEvent);
    if (!liabilityEvent || liabilityEvent?.eventId !== data.eventId) return;
    if (data.messageType === 'AGGREGATED_MARKET_LIABILITY') return;
    if (data.messageType === 'MARKET_LIABILITY') {
      const liabilityMarkets = cloneDeep(getters.liabilityEventMarkets);
      liabilityMarkets[data.marketId] = data;
      commit(types.SET_LIABILITY_EVENT_MARKETS, liabilityMarkets);
    }
    if (data.messageType === 'SELECTION_LIABILITY') {
      let marketSelections = cloneDeep(getters.getLiabilityMarketSelectionsById(data.marketId));
      const foundSelectionIndex = findIndex(marketSelections, { selectionId: data.selectionId });
      if (foundSelectionIndex < 0) {
        if (!marketSelections) {
          marketSelections = [data];
        } else {
          marketSelections.push(data);
        }
      } else {
        marketSelections[foundSelectionIndex] = data;
      }
      const allMarketsSelections = cloneDeep(state.liabilityMarketSelectionsObject);
      allMarketsSelections[data.marketId] = marketSelections;
      commit(types.SET_LIABILITY_EVENT_SELECTIONS_BY_MARKET_ID, allMarketsSelections);
    }
    if (data.messageType === 'EVENT_LIABILITY') {
      liabilityEvent.eventLiabilities.nodes[0] = {
        betsCount: data.betsCount,
        pnl: data.pnl,
        volume: data.volume,
      };
      commit(types.UPDATE_LIABILITY_EVENT, liabilityEvent);
    }
  },
  loadFiltersSportsAndCompetitions({ getters, commit }) {
    commit(types.SET_FILTERS_LOADING, true);
    api.findSportsAndCompetitions({
      sportId: getters.filtersSelectedSport?.sportId,
      searchValue: trim(getters.filtersSearchValue),
    })
      .then(({ sports, competitions }) => {
        commit(types.SET_FILTERS_SPORTS, sports);
        commit(types.SET_FILTERS_COMPETITIONS, competitions);
      })
      .catch((error) => {
        console.error(error);
        commit(types.SET_FILTERS_SPORTS, []);
        commit(types.SET_FILTERS_COMPETITIONS, []);
      })
      .finally(() => {
        commit(types.SET_FILTERS_LOADING, false);
      });
  },
  setFiltersSearchValue({ commit }, newSearchValue) {
    commit(types.SET_FILTERS_SEARCH_VALUE, newSearchValue);
  },
};
